"use client";
import { motion, MotionStyle } from "framer-motion";
import React from "react";
type Props = {
  children: React.ReactNode;
  className?: string;
  duration?: number;
  position?: number;
  visible?: boolean;
  style?: MotionStyle;
};
const DivAnimateYAxis = ({
  children,
  className,
  duration,
  position,
  visible,
  style
}: Props) => {
  return (
    <motion.div
      className={className ? className : ""}
      style={style}
      initial={{
        y: `${position ? position : 60}`,
        opacity: visible ? 1 : 0,
      }}
      whileInView={{
        y: 0,
        opacity: 1,
      }}
      transition={{
        duration: `${duration ? duration : 1.2}`,
        ease: "easeIn",
      }}
      viewport={{
        once: true,
      }}
    >
      {children}
    </motion.div>
  );
};

export default DivAnimateYAxis;
