"use client";
import { LOAD_GALLERY } from "@/services/api/api_constance";
import { callApi } from "@/services/api/base_api";
import Gallery from "@/types/Gallery";
import ServerDataProp from "@/types/ServerDataProp";
import { useEffect, useState } from "react";
import DivAnimateYAxis from "../utils/DivAnimateYAxis";

const GallerySection2: React.FC<ServerDataProp> = ({ setting }) => {
  const [galleryData, setGalleryData] = useState<Gallery[]>([]);
  useEffect(() => {
    callApi.get(LOAD_GALLERY).then(res => {
      setGalleryData(res.data);
    })
  }, [])
  return (
    <section className="rv-9-gallery">
      <DivAnimateYAxis position={30} visible>
        <div className="row justify-content-center row-cols-lg-5 row-cols-sm-3 row-cols-2 rv-inner-gallery-row">
          {galleryData.map((img, index) => (
            <div className="col" key={index}>
              <div className="rv-9-gallery__img">
                <img src={img.imageUrl} alt={img.title} />
              </div>
            </div>
          ))}
        </div>
      </DivAnimateYAxis>
      <div className="rv-9-gallery__sticker">
        <h4 className="title">Instagram</h4>
        <h6 className="sub-title">Theo dõi chúng tôi</h6>
      </div>
    </section>
  );
};

export default GallerySection2;
